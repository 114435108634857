import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { Canvas } from '@react-three/fiber';
import { Bounds, Box, Center, OrbitControls } from '@react-three/drei';
import Paper from './paper';
import gsap from 'gsap';
import { Camera } from '@capacitor/camera';
import ConfigPanel from './ConfigPanel';
import TopTitle from './TopTitle';
import TopAction from './TopAction';
import SafeArea from '../components/SafeArea';

function App() {
  const [activeIndex, setActiveIndex] = useState(0);
  const rotationRef = useRef(0);
  const [_, refresh] = useReducer(x => x + 1, 0);
  const animationsRef = useRef([]);
  const lastDirection = useRef('next');
  const [pages, setPages] = useState([]);

  const [width, setWidth] = useState(5);
  const [height, setHeight] = useState(5);
  const inputRef  = useRef(null);

  const [config, setConfig] = useState({
    width: 5,
    height: 5,
    depth: 1,
    cornerRadius: 0.04,
    paperColor: '#ffffff',
    padding: 0.02,
  });

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [configPanelVisible, setConfigPanelVisible] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    const savedLang = localStorage.getItem('language');
    if (savedLang) {
      setLanguage(savedLang);
    }
  }, []);

  const handleBackgroundImageChange = useCallback((file) => {
    if (!file) {
      setBackgroundImage(null);
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      setBackgroundImage(e.target?.result);
    };
    reader.readAsDataURL(file);
  }, []);

  const loadPhotos = () => {
    const input = inputRef.current
    console.log(input.files, input.files.length > 0)
    if (input.files.length > 0) {
      const files = input.files;
      const readers = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          setPages((prevState) => [...prevState, { id: i, furl: e.target?.result, burl: null }]);
        };
        reader.readAsDataURL(file);
      }
      Promise.all(readers.map((r) => new Promise((resolve) => (r.onload = resolve))))
        .then(() => input.value = '')
        .catch(console.error);
    }
    
  };

  const loadPhotosCap = async () => {
    try {
      const permission = await Camera.requestPermissions();
      if (permission.photos === 'granted') {
        // 让用户选择照片
        const result = await Camera.pickLimitedLibraryPhotos({
          limit: 8 // 限制选择8张照片
        });

        if (result.photos && result.photos.length > 0) {
          // 将照片转换为页面数据
          const photoPages = [];
          for (let i = 0; i < result.photos.length; i += 2) {
            const frontPhoto = result.photos[i];
            const backPhoto = result.photos[i + 1];
            
            photoPages.push({
              id: i / 2,
              furl: frontPhoto ? frontPhoto.webPath : '/test/1.jpg',
              burl: backPhoto ? backPhoto.webPath : '/test/5.jpg'
            });
          }

          setPages(photoPages);
        } else {
          // 如果用户没有选择照片，使用默认图片
          useDefaultPhotos();
        }
      } else {
        console.log('Camera permission not granted');
        useDefaultPhotos();
      }
    } catch (error) {
      console.error('Error loading photos:', error);
      useDefaultPhotos();
    }
  };

  const useDefaultPhotos = () => {
    setPages([
      { id: 0, furl: '/test/1.jpg', burl: '' },
      { id: 1, furl: '/test/2.jpg', burl: '' },
      { id: 2, furl: '/test/3.jpg', burl: '' },
      { id: 3, furl: '/test/4.jpg', burl: '' },
      { id: 4, furl: '/test/5.jpg', burl: '' },
      { id: 5, furl: '/test/6.jpg', burl: '' },
    ]);
  };

  // 添加选择照片的按钮
  const handleSelectPhotos = () => {
    loadPhotos();
  };

  useEffect(() => {
    useDefaultPhotos();
  }, [])

  const isFirstPage = activeIndex === 0;
  const isLastPage = activeIndex === pages.length;

  const setPageIndex = (newIndex) => {
    const clampedIndex = Math.max(0, Math.min(newIndex, pages.length));
    setActiveIndex(clampedIndex);
  };

  const dis = 0.01;

  const handleFlip = () => {
    if (!isLastPage) {
      const lastAnimation = animationsRef.current[animationsRef.current.length - 1];

      if (lastAnimation) {
        if (lastAnimation.pageId === activeIndex && lastDirection.current === 'prev') {
          lastDirection.current = 'next';
          lastAnimation.tween.reverse();
          setPageIndex(activeIndex + 1);
          refresh();
          return;
        }
      }

      lastDirection.current = 'next';
      setPageIndex(activeIndex + 1);

      const currentRotation = 0;
      const targetRotation = Math.PI - dis * (activeIndex + 1);

      const animTarget = { rotation: currentRotation };

      const newAnimation = {
        pageId: activeIndex,
        rotation: { current: currentRotation },
        tween: gsap.to(animTarget, {
          rotation: targetRotation,
          duration: 0.5,
          ease: "power2.inOut",
          onUpdate: function() {
            const anim = animationsRef.current.find(a => a.pageId === activeIndex);
            if (anim) {
              anim.rotation.current = animTarget.rotation;
            }
            refresh();
          },
          onComplete: function() {
            animationsRef.current = animationsRef.current.filter(a => a.pageId !== activeIndex);
            rotationRef.current = -(activeIndex + 1) * dis;
          },
          onReverseComplete: function() {
            animationsRef.current = animationsRef.current.filter(a => a.pageId !== activeIndex);
            rotationRef.current = -activeIndex * dis;
          }
        })
      };

      animationsRef.current.push(newAnimation);
    }
  };

  const handlePrev = () => {
    if (!isFirstPage) {
      const lastAnimation = animationsRef.current[animationsRef.current.length - 1];

      if (lastAnimation) {
        if (lastAnimation.pageId + 1 === activeIndex && lastDirection.current === 'next') {
          lastDirection.current = 'prev';
          lastAnimation.tween.reverse();
          setPageIndex(activeIndex - 1);
          refresh();
          return;
        }
      }

      lastDirection.current = 'prev';
      setPageIndex(activeIndex - 1);

      const currentRotation = Math.PI - dis * (activeIndex - 1);
      const targetRotation = 0;

      const animTarget = { rotation: currentRotation };

      const newAnimation = {
        pageId: activeIndex - 1,
        rotation: { current: currentRotation },
        tween: gsap.to(animTarget, {
          rotation: targetRotation,
          duration: 0.5,
          ease: "power2.inOut",
          onUpdate: function() {
            const anim = animationsRef.current.find(a => a.pageId === activeIndex - 1);
            if (anim) {
              anim.rotation.current = animTarget.rotation;
            }
            refresh();
          },
          onComplete: function() {
            animationsRef.current = animationsRef.current.filter(a => a.pageId !== activeIndex - 1);
            rotationRef.current = -(activeIndex - 1) * dis;
          },
          onReverseComplete: function() {
            animationsRef.current = animationsRef.current.filter(a => a.pageId !== activeIndex - 1);
            rotationRef.current = -(activeIndex - 1) * dis;
          }
        })
      };

      animationsRef.current.push(newAnimation);
    }
  };

  const getPageRotation = (pageId) => {
    const animation = animationsRef.current.find(a => a.pageId === pageId);
    if (animation) {
      return animation.rotation.current;
    }

    const flippingPage = animationsRef.current.find(a => a.pageId < pageId);

    if (activeIndex >= pageId) {
      if (flippingPage) {
        return 0 - pageId * dis;
      }
      return activeIndex === pageId ? rotationRef.current : Math.PI - pageId * dis;
    }
    return 0 - pageId * dis;
  };

  return (
    
      <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
        <SafeArea>
          <TopTitle language={language} />
          <Canvas style={{background: backgroundImage ? `url(${backgroundImage})` : 'var(--bg-color)'}}>
            <ambientLight />
            <Center>
              <group rotation={[0, 0, 0]}>
                {pages.map((page) => (
                  <Paper
                    key={page.id}
                    angle={getPageRotation(page.id)}
                    furl={page.furl}
                    burl={page.burl}
                    width={config.width}
                    height={config.height}
                    raduis={config.cornerRadius}
                    borderSize={config.padding}
                    paperColor={config.paperColor}
                  />
                ))}
              </group>
              <Bounds fit clip observe margin={1.2} maxDuration={1}>
                <Box visible={false} args={[width * 2, height, 0.1]} rotation={[Math.PI / 2, 0, 0]} />
              </Bounds>
            </Center>
            <OrbitControls />
          </Canvas>
          <div style={{
            position: 'absolute',
            bottom: '10%',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            gap: '20px'
          }}>
            <button
              onClick={handlePrev}
              className="absolute right-4 top-0 transform -translate-y-[120px]
                px-2 py-1 text-lg font-medium rounded-lg
                bg-primary hover:bg-primary-dark text-white whitespace-nowrap
                dark:bg-gray-800 dark:hover:bg-gray-700
                transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isFirstPage}
            >
              {language === 'en' ? 'Prev' : '上一页'}
            </button>
            
            <button
              onClick={handleFlip}
              className="absolute left-4 top-0 transform -translate-y-[120px]
                px-2 py-1 text-lg font-medium rounded-lg 
                bg-primary hover:bg-primary-dark text-white whitespace-nowrap
                dark:bg-gray-800 dark:hover:bg-gray-700
                transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={isLastPage}
            >
              {language === 'en' ? 'Next' : '下一页'}
            </button>

            <input
              type="file"
              multiple
              accept="image/*"
              ref={inputRef}
              onChange={handleSelectPhotos}
              className="absolute bottom-4 left-1/2 transform -translate-x-1/2
                w-auto text-sm text-gray-500 dark:text-gray-400
                file:mr-4 file:py-2 file:px-4
                file:rounded-md file:border-0
                file:text-sm file:font-semibold
                file:bg-primary file:text-white
                hover:file:bg-primary-dark
                hidden
                dark:file:bg-gray-800 dark:hover:file:bg-gray-700"
            />
            <button
              onClick={() => inputRef.current.click()}
              className="absolute left-0 bottom-8 transform -translate-x-1/2 
                block text-sm dark:text-gray-400
                px-6 py-2 font-medium rounded-lg
                bg-primary hover:bg-primary-dark text-white whitespace-nowrap
                dark:bg-gray-800 dark:hover:bg-gray-700
                transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {language === 'en' ? 'Select Photos' : '选择照片'}
            </button>

            
          </div>

          <div>
            <TopAction 
              configPanelVisible={configPanelVisible}
              setConfigPanelVisible={setConfigPanelVisible}
              onThemeChange={(isDark) => {
                // 主题切换时不需要额外操作
              }}
              onLanguageChange={(lang) => {
                setLanguage(lang);
                localStorage.setItem('language', lang);
              }}
            />
            {configPanelVisible && (
              <ConfigPanel
                visible={configPanelVisible}
                config={config}
                onConfigChange={setConfig}
                onBackgroundImageChange={handleBackgroundImageChange}
                onClose={() => setConfigPanelVisible(false)}
                language={language}
              />
            )}
          </div>
        </SafeArea>
        <style jsx global>{`
          :root {
            --bg-color: #f5f5f5;
          }
          
          .dark {
            --bg-color: #1a1a1a;
          }
        `}</style>
      </div>
  );
}

export default App;