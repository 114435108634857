import { memo } from 'react';
import { useTranslation } from '../../hooks/useTranslation';

const StyleConfig = ({ cornerRadius, paperColor, padding, onChange, language }) => {
  const { t } = useTranslation(language);

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
        {t('config.style.title')}
      </h3>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('config.style.cornerRadius')}
        </label>
        <input
          type="range"
          value={cornerRadius}
          onChange={(e) => onChange('cornerRadius', parseFloat(e.target.value))}
          min={0}
          max={0.1}
          step={0.01}
          className="w-full"
        />
        <span className="text-sm text-gray-500">{cornerRadius.toFixed(2)}</span>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('config.style.paperColor')}
        </label>
        <input
          type="color"
          value={paperColor}
          onChange={(e) => onChange('paperColor', e.target.value)}
          className="w-full h-10 rounded-md"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {t('config.style.padding')}
        </label>
        <input
          type="range"
          value={padding}
          onChange={(e) => onChange('padding', parseFloat(e.target.value))}
          min={0}
          max={0.2}
          step={0.01}
          className="w-full"
        />
        <span className="text-sm text-gray-500">{padding.toFixed(2)}</span>
      </div>
    </div>
  );
};

export default memo(StyleConfig);