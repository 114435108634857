import { Routes, Route } from 'react-router';
import './App.css';
import Book from './book/book'
import Test from './Test/index'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Test />} />
        {/* <Route path="/test" element={<Book />} /> */}
      </Routes>
    </div>
  );
}

export default App;
