import React from 'react';

const SafeArea = ({ children, className }) => {
  return (
    <div 
      className={`
        w-full h-full box-border relative
        pt-[env(safe-area-inset-top)]
        pr-[env(safe-area-inset-right)]
        pb-[env(safe-area-inset-bottom)]
        pl-[env(safe-area-inset-left)]
        ${className || ''}
      `}
    >
      {children}
    </div>
  );
};

export default SafeArea;
