import { useCallback } from 'react';
import { translations } from '../locales';

export const useTranslation = (language) => {
  const t = useCallback((key) => {
    const keys = key.split('.');
    let value = translations[language];
    
    for (const k of keys) {
      if (value && value[k]) {
        value = value[k];
      } else {
        console.warn(`Translation key not found: ${key}`);
        return key;
      }
    }
    
    return value;
  }, [language]);

  return { t };
};
