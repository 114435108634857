import React, { useState } from 'react';
import LanguageToggle from '../components/LanguageToggle';
import ThemeToggle from '../components/ThemeToggle';

const TopAction = ({ configPanelVisible, setConfigPanelVisible, onThemeChange, onLanguageChange }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="fixed top-10 right-4 flex items-center gap-2">
      <LanguageToggle onToggle={onLanguageChange} expanded={expanded} />
      <ThemeToggle onToggle={onThemeChange} expanded={expanded} />
      <button
        onClick={() => setConfigPanelVisible(!configPanelVisible)}
        className={`
          w-10 h-10 flex items-center justify-center
          bg-primary hover:bg-primary-dark text-white rounded
          transition-all duration-300 ease-in-out
          dark:bg-gray-800 dark:hover:bg-gray-700
        `}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </button>
      <button
        onClick={toggleExpanded}
        className={`
          w-10 h-10 flex items-center justify-center
          bg-primary hover:bg-primary-dark text-white rounded
          transition-all duration-300 ease-in-out
          dark:bg-gray-800 dark:hover:bg-gray-700
          ${expanded ? 'rotate-180' : 'rotate-0'}
        `}
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
    </div>
  );
};

export default TopAction;
