import { memo, useRef } from 'react';
import { useTranslation } from '../../hooks/useTranslation';

const BackgroundConfig = ({ onImageChange, language }) => {
  const { t } = useTranslation(language);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    if (e.target.files?.length) {
      onImageChange(e.target.files[0]);
    }
  };

  const handleClearImage = () => {
    onImageChange(null);
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">
        {t('config.backgroundImage')}
      </h3>
      
      <div className="space-y-2">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*"
          className="hidden"
        />
        <button
          onClick={() => fileInputRef.current?.click()}
          className="w-full px-4 py-2 text-sm font-medium text-white
            bg-primary hover:bg-primary-dark
            dark:bg-gray-800 dark:hover:bg-gray-700
            rounded-lg transition-colors
            flex items-center justify-center space-x-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span>{t('config.chooseImage')}</span>
        </button>
        <button
          onClick={handleClearImage}
          className="w-full px-4 py-2 text-sm font-medium text-white
            bg-red-500 hover:bg-red-600
            dark:bg-red-700 dark:hover:bg-red-800
            rounded-lg transition-colors
            flex items-center justify-center space-x-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
          </svg>
          <span>{t('config.clearImage')}</span>
        </button>
      </div>
    </div>
  );
};

export default memo(BackgroundConfig);