import React, { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three'

function ShaderMaterialComponent({ side, customUniforms = {} }) {
  const defaultUniforms = {
    width: { value: 1.0 },
    height: { value: 1.0 },
    angle: { value: 0.0 },
    frontTexture: { value: null },
    borderSize: { value: 0.02 },
    borderRadius: { value: 0.02 },
    paperColor: { value: new THREE.Color('#ffffff') }
  };

  const uniforms = useMemo(() => ({ ...defaultUniforms, ...customUniforms }), []);

  const materialRef = useRef(null);

  useEffect(() => {
    if (!customUniforms.angle) return
    materialRef.current.uniforms['angle'].value = customUniforms.angle.value
  }, [customUniforms.angle]);

  useEffect(() => {
    if (!customUniforms.width) return
    materialRef.current.uniforms['width'].value = customUniforms.width.value
  }, [customUniforms.width]);

  useEffect(() => {
    if (!customUniforms.height) return
    materialRef.current.uniforms['height'].value = customUniforms.height.value
  }, [customUniforms.height]);

  useEffect(() => {
    if (!customUniforms.borderRadius) return
    materialRef.current.uniforms['borderRadius'].value = customUniforms.borderRadius.value
  }, [customUniforms.borderRadius]);

  useEffect(() => {
    if (!customUniforms.borderSize) return
    materialRef.current.uniforms['borderSize'].value = customUniforms.borderSize.value
  }, [customUniforms.borderSize]);

  useEffect(() => {
    if (!customUniforms.paperColor) return
    if (typeof customUniforms.paperColor.value === 'string') {
      materialRef.current.uniforms['paperColor'].value = new THREE.Color(customUniforms.paperColor.value);
    } else {
      materialRef.current.uniforms['paperColor'].value = customUniforms.paperColor.value;
    }
  }, [customUniforms.paperColor]);

  // useEffect(() => {
  //   if (!customUniforms.frontTexture) return
  //   materialRef.current.uniforms['frontTexture'].value = customUniforms.frontTexture.value
  // }, [customUniforms.frontTexture]);

  const vertexShader = useMemo(() => `
    varying vec3 vNormal;
    varying vec2 vUv;
    uniform float width;
    uniform float height;
    uniform float angle;
    void main() {
      vec3 pos = position;

      float base = 1.0 - (pos.x + width / 2.0) / width;

      // curve
      float offsetW = (pos.x - width / 2.0) * (pos.x - width / 2.0) / 10.0 * angle;
      float offsetH = (pos.y - height / 2.0) * (pos.y - height / 2.0) / 30.0 * angle;
      pos.z -= base * offsetW;
      pos.z -= base * offsetH;

      pos.x += base * abs(offsetW);
      pos.x += base * abs(offsetH);

      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    }
  `, []);

  const fragmentShader = useMemo(() => `
    uniform sampler2D frontTexture;
    uniform float width;
    uniform float height;
    uniform float borderSize;
    uniform float borderRadius;
    uniform vec3 paperColor;
    varying vec3 vNormal;
    varying vec2 vUv;
    void main() {
      vec2 uv = vUv;

      vec4 baseColor = vec4(paperColor, 1.0);
      vec2 currentPos = vec2(abs(uv.x - 0.5), abs(uv.y - 0.5));
      vec2 targetPos = vec2(0.5 - borderRadius, 0.5 - borderRadius);
      float distance = length(currentPos - targetPos);
      // book radius
      if (distance > borderRadius && 0.5 - uv.x > (0.5 - borderRadius) && abs(uv.y - 0.5) > (0.5 - borderRadius)) {
        baseColor.a = 0.0;
      }

      vec2 texSize = vec2(textureSize(frontTexture, 0));
      float aspect = texSize.x / texSize.y;
      float screenAspect = width / height;

      if (aspect > screenAspect) {
        uv.y = (uv.y - 0.5) * aspect + 0.5;
      } else {
        uv.x = (uv.x - 0.5) / aspect + 0.5;
      }

      float border = smoothstep(0.0, borderSize, uv.x - borderSize) * 
                     smoothstep(0.0, borderSize, uv.y - borderSize) * 
                     smoothstep(0.0, borderSize, 1.0 - uv.x - borderSize) * 
                     smoothstep(0.0, borderSize, 1.0 - uv.y - borderSize);

      vec4 frontColor = texture2D(frontTexture, uv);
      if (frontColor.a == 0.0) {
        frontColor = baseColor;
      }      
      vec4 finalColor = mix(baseColor, frontColor, border);   
      
      gl_FragColor = finalColor;
    }
  `, []);

  return (
    <shaderMaterial
      ref={materialRef}
      side={side}
      args={[{
        uniforms,
        vertexShader,
        fragmentShader
      }]}
      transparent
      depthWrite={false} // Disable depth writing for transparency
      depthTest={true}   // Enable depth testing
      blending={THREE.NormalBlending} // Use normal blending
    />
  );
}

export default ShaderMaterialComponent;