import React, { useState, useEffect } from 'react';

const LanguageToggle = ({ onToggle, expanded = true }) => {
  const [isEnglish, setIsEnglish] = useState(true);

  useEffect(() => {
    const savedLang = localStorage.getItem('language');
    setIsEnglish(savedLang !== 'zh');
  }, []);

  const toggleLanguage = () => {
    const newIsEnglish = !isEnglish;
    setIsEnglish(newIsEnglish);
    localStorage.setItem('language', newIsEnglish ? 'en' : 'zh');
    
    if (onToggle) {
      onToggle(newIsEnglish ? 'en' : 'zh');
    }
  };

  return (
    <button
      onClick={toggleLanguage}
      className={`
        w-10 h-10 flex items-center justify-center
        bg-primary hover:bg-primary-dark text-white rounded
        transition-all duration-300 ease-in-out
        dark:bg-gray-800 dark:hover:bg-gray-700
        ${expanded ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10 pointer-events-none'}
      `}
    >
      {isEnglish ? 'EN' : '中'}
    </button>
  );
};

export default LanguageToggle;
