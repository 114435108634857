import { memo } from 'react';

const DimensionsConfig = ({ width, height, depth, onChange }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200">尺寸</h3>
      
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">宽度</label>
          <input
            type="range"
            value={width}
            onChange={(e) => onChange('width', parseFloat(e.target.value))}
            min={1}
            max={10}
            step={0.1}
            className="w-full"
          />
          <span className="text-sm text-gray-500">{width.toFixed(1)}</span>
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">高度</label>
          <input
            type="range"
            value={height}
            onChange={(e) => onChange('height', parseFloat(e.target.value))}
            min={1}
            max={10}
            step={0.1}
            className="w-full"
          />
          <span className="text-sm text-gray-500">{height.toFixed(1)}</span>
        </div>
        
      </div>
    </div>
  );
};

export default memo(DimensionsConfig);