// App.js
import React, { useRef, useMemo } from 'react';
import { TextureLoader, Color } from 'three';
import ShaderMaterialComponent from './paperShader';

function Paper({width = 5.0, height = 5.0, angle = 0, furl = "", burl="", raduis = "0.04", borderSize = "0.02", paperColor = "#ffffff"}) {
  const frontTexture = useMemo(() => furl && new TextureLoader().load(furl) , [furl]);
  const backTexture = useMemo(() => burl && new TextureLoader().load(burl) , [burl]);
  const color = useMemo(() => new Color(paperColor), [paperColor]);

  return (
    <group rotation={[0, angle * -1 + Math.PI, 0]}>
      <mesh position={[-width /2 , 0 ,0]}>
        <planeGeometry args={[width, height, 320, 320]} />
        <ShaderMaterialComponent
          side={0}
          customUniforms={{
            width: { value: width },
            height: { value: height },
            angle: { value: Math.sin(angle) * 0.5 },
            frontTexture: { value: backTexture },
            borderRadius: { value: raduis },
            borderSize: {value: borderSize},
            paperColor: { value: color }
          }}
        />

      </mesh>

      <mesh position={[-width /2 , 0 ,0]}>
        <planeGeometry args={[width, height, 320, 320]} />
        <ShaderMaterialComponent
          side={1}
          customUniforms={{
            width: { value: width },
            height: { value: height },
            angle: { value: Math.sin(angle) * 0.5 },
            frontTexture: { value: frontTexture },
            borderRadius: { value: raduis },
            borderSize: {value: borderSize},
            paperColor: { value: color }
          }}
        />
      </mesh>
    </group>
  );
}

export default Paper