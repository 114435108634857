import React from 'react';
import { useTranslation } from '../hooks/useTranslation';

const TopTitle = ({ language }) => {
  const { t } = useTranslation(language);

  return (
    <div className="
      absolute top-5 left-1/2 -translate-x-1/2
      z-50 select-none
      text-2xl font-bold
      text-gray-800 dark:text-gray-200
      drop-shadow-md
    ">
      {t('title')}
    </div>
  );
};

export default TopTitle;
