export const translations = {
  en: {
    title: '3D Photo Gallery',
    buttons: {
      prev: 'Prev',
      next: 'Next',
      selectPhotos: 'Select Photos',
      settings: 'Settings',
      close: 'Close'
    },
    config: {
      backgroundImage: 'Background Image',
      chooseImage: 'Choose Image',
      clearImage: 'Clear Image',
      dimensions: 'Dimensions',
      style: {
        title: 'Style',
        cornerRadius: 'Corner Radius',
        paperColor: 'Paper Color',
        padding: 'Padding'
      }
    }
  },
  zh: {
    title: '3D 相册',
    buttons: {
      prev: '上一页',
      next: '下一页',
      selectPhotos: '选择照片',
      settings: '设置',
      close: '关闭'
    },
    config: {
      backgroundImage: '背景图片',
      chooseImage: '选择图片',
      clearImage: '清除图片',
      dimensions: '尺寸',
      style: {
        title: '样式',
        cornerRadius: '圆角',
        paperColor: '纸张颜色',
        padding: '内边距'
      }
    }
  }
};
